import { formatError } from "./api-errors";
import { format, differenceInHours } from "date-fns";
import { pluralize } from "inflected";
import { get } from "lodash-es";
import PropTypes from "prop-types";
import buildCloudinaryUrl from "./build-cloudinary-url";

export const activeMenu = ({ url, isExact }, to) => {
  return url === to && isExact ? "active" : "";
};

export const isActiveMenu = ({ url, isExact }, to) => {
  return url === to && isExact;
};

export const refreshPage = (force = false) => {
  window.location.reload(force);
};

export const formatPhone = (p, countryCode) => {
  const callingCode = countriesWithCodes[countryCode];
  if (`${p}`.length < 9) {
    return p; // IMPORTANT: could be user_no
  }

  p = p.replace(callingCode, "");
  if (p.charAt(0) === "0") {
    p = p.substr(1);
  }
  return `${callingCode}${p}`;
};

export const formatPhoneNumber = (phone = "") => {
  if (!phone) {
    return "N/A";
  }

  const removeprefix = `${phone}`.replace("+254", "0");
  const match = removeprefix.match(/^(\d{4})(\d{3})(\d{3})$/);
  if (match) {
    return `${match[1]} ${match[2]} ${match[3]}`;
  }
  return "-";
};

export const addPhonePrefix = phoneNumber => {
  if (phoneNumber.charAt(0) === "0") {
    return phoneNumber.replace("0", "+254");
  }
  if (phoneNumber.substring(0, 3) === "254") {
    return `+${phoneNumber}`;
  }
  return phoneNumber;
};

export const formatNumber = number => number.toLocaleString();

export const formatDate = date => {
  const formatedDate = format(new Date(date), "do MMM y");
  return formatedDate;
};

export const getTime = date => {
  const time = format(new Date(date), "hh:mma");
  return time;
};

export const DayFormat = date => {
  const formatedDate = format(new Date(date), "eee d, MMM y");
  return formatedDate;
};

export const formatCurrency = (amount, currency = "") =>
  `${currency} ${(Math.round(Number(amount) * 100) / 100).toLocaleString()}`;

export const isAfterXHours = ({ date, hours }) => {
  const diff = differenceInHours(new Date(), new Date(date));
  return diff >= hours;
};

export const formatText = value => {
  if (!value) {
    return value;
  }
  return value.split("_").join(" ");
};

export const pluralizeWord = (value = 0, unit = "") => {
  if (value === 1 || unit === "") {
    return unit;
  }
  return pluralize(`${unit}`);
};

export const filterProductsByDomain = (products, domain) => {
  const filteredProducts = products.reduce((result, newProduct) => {
    const product = newProduct.node;
    const uoms = get(product, "units_of_measure", []);
    const activatedDomainUOM = uoms.find(
      uom => uom?.business_domain?.domain_name === domain && uom.active
    );
    const activeProductDomain = product?.domain_activations?.find(
      ({ business_domain }) => business_domain.domain_name === domain
    );

    if (activeProductDomain && product.product_id) {
      result.push({
        label: product.product_name,
        value: product.product_id,
        pkg: product.packaging_units,
        product_code: product?.product_code,
        product_items: product?.product_items
      });
    } else if (activatedDomainUOM && product.product_item_id) {
      result.push({
        label: product.product_item_name,
        value: product.product_item_id,
        units_of_measure: activatedDomainUOM.uom,
        conversion_ratio: get(activatedDomainUOM, "conversion_ratio", null)
      });
    }
    return result;
  }, []);

  return filteredProducts;
};

export const filterProductItemsByDomain = (productItems, domain) => {
  const filteredProducts = productItems.reduce((result, productItem) => {
    const newProductItem = productItem.node;
    const uoms = get(newProductItem, "units_of_measure", []);
    const activatedDomainUOM = uoms.find(
      uom => uom?.business_domain?.domain_name === domain && uom.active
    );
    if (activatedDomainUOM) {
      result.push({
        label: `${newProductItem.product.product_name} - ${newProductItem.product_item_name}`,
        itemName: newProductItem.product_item_name,
        value: newProductItem.product_item_id,
        units_of_measure: activatedDomainUOM.uom,
        conversion_ratio: get(activatedDomainUOM, "conversion_ratio", null),
        pkg: newProductItem.product.packaging_units,
        product: newProductItem.product
      });
    }
    return result;
  }, []);
  return filteredProducts;
};

export const balance = record => {
  const total_payable = get(record, "payment.total_payable", 0);
  const amount_paid = get(record, "payment.amount_paid", 0);

  return total_payable - amount_paid;
};

export const validateFarmerType = farmerType => {
  if (
    farmerType === "NON_CONTRACT_FARMER" ||
    farmerType === "CONTRACT_FARMER"
  ) {
    return true;
  } else {
    return false;
  }
};

export const getPaymentStatus = deliveryStatuses => {
  const { delivery_status } = deliveryStatuses.slice(-1)[0];
  return delivery_status;
};

export const removeDuplicates = (data, key) => {
  return [...new Map(data.map(item => [key(item), item])).values()];
};

export const reformatPhoneNumber = phoneNumber => {
  return typeof phoneNumber === "string"
    ? phoneNumber.replace("+254", "0")
    : phoneNumber;
};

export const filterProductsByType = (filteredProductType, product_type) =>
  filteredProductType?.value === product_type;

export const formatRegistrationNumber = regNo => {
  const registrationNumber =
    regNo.indexOf(" ") === -1
      ? regNo.substring(0, 3) + " " + regNo.substring(3, regNo.length)
      : regNo;
  return registrationNumber.toUpperCase();
};

export { formatError, buildCloudinaryUrl };

isAfterXHours.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  hours: PropTypes.number.isRequired
};

// needed for client side validations outside
// components
const countriesWithCodes = {
  KE: "+254",
  UG: "+256",
  TZ: "+255"
};

export const isLastMileDispatch = fdr_items => {
  const constainsRoutePlan = fdr_items.some(element => {
    if (element.route_plan_id) {
      return true;
    }

    return false;
  });
  return constainsRoutePlan;
};

export const formatTimeWindow = (start, end) => {
  if (!start || !end) return "All Day";
  return `${format(new Date(start), "hh:mm a")} - ${format(
    new Date(end),
    "hh:mm a"
  )}`;
};
